(function ($) {
  $(document).ready(function(){
    $(".slider.reviews .owl-carousel").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      // navigation
      nav: true,
      dots: false,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
    });
    
    var windowWidth = $(window).width();
    if (windowWidth > 1200) {
    
        $(window).scroll(function(){
            if ($(window).scrollTop() > 0) {
                $('header').addClass('position-fixed');
                $('header').addClass('sticky');
                $('body').addClass('sticky');
            }
            else {
                $('header').removeClass('sticky');
                $('header').removeClass('position-fixed');
                $('body').removeClass('sticky');
            }
        });
        $(window).scroll(function(){
            if ($(window).scrollTop() > 400) {
                $('.mini_search_book .btn-book').addClass('sticky');
            }
            else {
                $('.mini_search_book .btn-book').removeClass('sticky');
            }
        });

    }
    
  });
})(jQuery);
